import React from "react";
import Layout from "../../other/layout";
import BeingADFSDealer from "../../components/AC-StaticPages/why-choose-dfs/being-a-dfs-dealer/BeingADFSDealer";
import SEO from "../../other/seo";

export default function beingADFSDealer() {
  return (
    <Layout>
      <SEO title="Being ADFS Dealer" />

      <BeingADFSDealer />
    </Layout>
  );
}
