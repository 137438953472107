import React from "react";
import BecomingDealer_Hero from "../../../../assets/img/BecomingDealer_Hero.jpg";

import styles from "../../style/styles.module.css";

export default function WhyChooseDFS() {
  return (
    <div className={styles.container}>
      <div className={styles.rowSale}>
        <div className={styles.content}>
          <h1 className={styles.title}>Being a DFS Dealer</h1>
          <h2>Exceptional opportunities await you!</h2>
          <p className={styles.toolParag}>
            DFS is your source for the products, marketing tools and service you
            need to profitably meet your customers’ needs.
          </p>
          <img
            className={styles.hero}
            src={BecomingDealer_Hero}
            alt="Two men shaking hands"
          />

          <p className={styles.productMargin}>
            As a DFS Dealer, you can look forward to a proven reseller program
            and valuable advantages that will help you thrive.
          </p>
          <p>Dealer benefits:</p>
          <ul>
            <li>High-demand business products that meet more customer needs</li>
            <li>Low, wholesale pricing and generous margins</li>
            <li>Award-winning customer service and support</li>
            <li>Fast 3-6 day delivery on most orders</li>
            <li>
              Professionally produced digital and print marketing tools to help
              you sell
            </li>
            <li>100% Quality Guarantee</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
